





































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class OrderDetails extends Vue {
  loading: boolean = false;
  id!: any;
  userData: any = null;
  pagerHeader: any = {
    title: "会员详细",
    desc: "会员详细",
    breadcrumb: ["会员管理", "会员详细"]
  };
  async readerUserInfo() {
    this.loading = true;
    try {
      var res = await this.$ajax.get("/api/services/app/Member/GetMemberInfo", {
        params: {
          id: this.id,
          includeAgent: true,
          includeMerchants: true,
          StatisticTodayProfit:true
        }
      });
      this.userData = res.data.result;
    } catch (error) {}
    this.loading = false;
  }
  created() {
    this.id = this.$route.query.id;
    this.readerUserInfo();
  }
  activated() {
    if (this.id != this.$route.query.id) {
      this.id = this.$route.query.id;
      this.readerUserInfo();
    }
  }
}
